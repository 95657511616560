.sidebar {
  max-width: calc(100vw - 3em);
  width: 400px;
  height: calc(100vh - 4px);
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999999999;
  transition: left 0.5s;
  border: 2px solid black;
  overflow: scroll;
}

.sidebar.hide {
  left: calc(-400px - 4px);
}

.MuiAccordion-root {
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
}

.MuiAccordion-root:not(:last-child) {
  border-bottom: 0
}

.MuiAccordion-root::before {
  display: none;
}

.MuiAccordionDetails-root {
  border-top: 1px solid lightgray;
}

.sidebar-overlay {
  background-color: black;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.5s, opacity 0.5s linear;
  cursor: pointer;
}
.sidebar-overlay.show {
  visibility: visible;
  opacity: 0.5;
  /* transition: visibility 0.5s step-end, opacity 0.5s linear; */
}
