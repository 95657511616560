.map-pin {
    display: flex;
    flex-grow: 1;
  }
  
  .map-pin.circle > svg {
    height: 13px;
    width: 13px;
  }
  .map-pin.xMark > svg {
    height: 24px;
    width: 24px;
  }
  
  .leaflet-popup-tip {
    display: none;
  }
  
  
  
  .person-popup .leaflet-popup-content-wrapper {
    background-color: #F5F5F5;
    border-radius: 10px;
    color: #4E4949;
    /* opacity: 0.2; */
    border: 1px solid black;
  }
  
  .location-popup .leaflet-popup-content-wrapper {
    background-color: #4E4949;
    border-radius: 10px;
    color: #F5F5F5;
    text-align: center;
  }
  .location-popup .leaflet-popup-content {
    margin: 0.5em 1em;
    font-weight: bold;
    width: fit-content;
  }
  .location-popup .leaflet-popup-content > h4 {
    all: unset;
  }
  .person-popup .leaflet-popup-content > h4 {
    margin: 0px;
  }
  
  
  