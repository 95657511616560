.react-modal-sheet-drag-indicator {
    background-color: black !important;
}

.react-modal-sheet-content {
    /* margin: 1em; */
}

.result-item {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

.result-content {
    margin: 1em
}

.result-filters {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}